import { SHARE_REQUEST_STATUSES } from "@/components/constants.js"

export default [
  {
    value: "createdAt",
    text: "Created At",
    width: 150,
    sortable: true,
    filterable: true,
  },
  {
    value: "shareeEmail",
    text: "Sharee Email",
    width: 190,
    sortable: true,
    filterable: true,
  },
  {
    value: "camera",
    text: "Camera",
    width: 200,
    sortable: true,
    filterable: true,
  },
  {
    value: "sharer",
    text: "Sharer",
    width: 150,
    sortable: true,
    filterable: true,
  },
  {
    value: "rights",
    text: "Rights",
    width: 80,
    align: "center",
    sortable: true,
    filterable: true,
  },
  {
    value: "status",
    text: "Status",
    width: 80,
    sortable: true,
    filterable: true,
    toStringFn: (item, key) =>
      SHARE_REQUEST_STATUSES.find((el) => el.value === item[key]).text,
  },
  {
    value: "message",
    text: "Message",
    width: 400,
    sortable: true,
    filterable: true,
  },
]
