<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="share requestes"
          :headers="headers"
          show-select
          item-key="id"
          :provider="provider"
          :filter-fields="filterFields"
        >
          <template #actions-right>
            <v-btn dark small color="error" @click="deleteAll"> Delete</v-btn>
            <v-btn dark small color="primary" @click="resendShareRequests">
              Resend
              <v-icon right> far fa-paper-plane </v-icon>
            </v-btn>
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                  Set Status
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(status, index) in statusList"
                  :key="index"
                  @click="updateShareRequestStatus(status.value)"
                >
                  <v-list-item-title>{{ status.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template #item.createdAt="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template #item.message="{ item }">
            <EReadMore :content="item.message" :max-length="80" />
          </template>
          <template #item.camera="{ item }">
            <span
              class="cursor-pointer primary--text"
              @click="cameraDialogStore.openDialog({ camera: item.cameraExid })"
              >{{ item.camera }}
            </span>
          </template>
          <template #item.status="{ item }">
            {{ getStatus(item.status) }}
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script lang="ts">
import ShareRequestsHeader from "@/components/shareRequests/shareRequestsHeaders"
import filterFields from "@/components/shareRequests/shareRequestsSearchFilters"
import { SHARE_REQUEST_STATUSES } from "@/components/constants"
import ReportTable from "@/components/ReportTable"
import { ShareRequestStatus } from "@evercam/shared/types/shares"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import Vue from "vue"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default Vue.extend({
  components: {
    ReportTable,
  },
  data: () => {
    return {
      headers: ShareRequestsHeader,
      filterFields,
      statusList: [
        { value: ShareRequestStatus.Pending, text: "Pending" },
        { value: ShareRequestStatus.Failed, text: "Failed" },
      ],
    }
  },
  head() {
    return {
      title: "Admin - Share Requests",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore, useCameraDialogStore),
    provider() {
      return AdminApi.shareRequests.getShareRequests
    },
    selectedToResend() {
      return this.reportStore.selectedItems.filter(
        (item) => item.status === ShareRequestStatus.Pending
      )
    },
  },
  created() {
    this.reportStore.initialSearchParams = {
      ...this.reportStore.initialSearchParams,
      status: ShareRequestStatus.Pending,
    }
    this.reportStore.searchFilters = {}
  },
  methods: {
    async deleteAll() {
      if (this.reportStore.selectedItems.length === 0) {
        this.$notifications.warn("At least select one share request!")
      } else {
        let ids = this.reportStore.selectedItems.map((el) => el.id).join()
        if (
          await this.$confirmDialog.open({
            title: "Delete Share Request(s)",
            message:
              "Are you sure you want to delete the seleted camera share request(s)?",
          })
        ) {
          try {
            await AdminApi.shareRequests.deleteShareRequest({
              ids: ids,
            })
            this.reportStore.selectedItems.forEach((request) => {
              this.reportStore.items = this.reportStore.items.filter(
                (item) => item.id !== request.id
              )
            })
            this.reportStore.selectedItems = []
            this.$notifications.success(
              "Camera Share Request(s) has been deleted!"
            )
          } catch (error) {
            this.$notifications.error({
              text: "Delete camera share request(s) failed!",
              error,
            })
          }
        }
      }
    },
    async updateShareRequestStatus(status) {
      if (this.reportStore.selectedItems.length === 0) {
        this.$notifications.warn("At least select one share request!")
      } else {
        const ids = this.reportStore.selectedItems
          .map((item) => item.id)
          .join(",")
        if (
          await this.$confirmDialog.open({
            title: "Change Share Request(s) Status",
            message:
              "Are you sure you want to change the camera share request(s) status?",
          })
        ) {
          try {
            await AdminApi.shareRequests.updateShareRequestStatus({
              ids,
              status,
            })
            this.reportStore.selectedItems.forEach((request) => {
              this.reportStore.items = this.reportStore.items.filter(
                (item) => item.id !== request.id
              )
            })
            this.reportStore.selectedItems = []
            this.$notifications.success(
              "Camera Share Request(s) status has been updated."
            )
          } catch (error) {
            this.$notifications.error({
              text: "Update camera share request(s) failed",
              error,
            })
          }
        }
      }
    },
    getStatus(status) {
      return SHARE_REQUEST_STATUSES.find((el) => el.value === status)?.text
    },
    async resendShareRequests() {
      if (this.selectedToResend.length === 0) {
        this.$notifications.warn("At least select one pending share request!")
      } else {
        for (let index = 0; index < this.selectedToResend.length; index++) {
          try {
            const shareRequest = this.selectedToResend[index]
            await EvercamApi.shares.resendShareRequest(
              shareRequest.cameraExid,
              {
                email: shareRequest.shareeEmail,
                apiId: shareRequest.userApiId,
                apiKey: shareRequest.userApiKey,
              }
            )
          } catch (error) {
            this.$notifications.error({
              text: "Failed to resend camera share request!",
              error,
            })
          }
        }
        this.$notifications.success(
          "A notification email has been sent to the specified emails addresses."
        )
      }
    },
  },
})
</script>
